import React from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'gatsby';
import { Typography, Box, Button } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FiArrowRight } from 'react-icons/fi';

const Article = ({ data }) => {
  const image = getImage(data.slika.localFile);
  return (
    <ArticleWrapper align={data.align}>
      <ArticleImage>
        <GatsbyImage alt="" image={image} loading="lazy" />
      </ArticleImage>
      <ArticleContent className={`article-content-${data.align}`}>
        <Typography variant="h3" gutterBottom>
          {data.naslov}
        </Typography>
        <Box mt={1} mb={3}>
          <Typography variant="subtitle1">{data.tekst}</Typography>
        </Box>
        {data.prica && (
          <Button
            variant="text"
            color="primary"
            size="large"
            endIcon={<FiArrowRight />}
            to={data.prica.url}
            component={RouterLink}
          >
            {data.action || 'Saznaj više'}
          </Button>
        )}
      </ArticleContent>
    </ArticleWrapper>
  );
};

const ArticleWrapper = styled.article`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: ${({ align }) => (align === 'left' ? 'row' : 'row-reverse')};

  img {
    border-radius: 12px;
  }

  ${({ theme }) => `
  margin: ${theme.spacing(10)} 0;

  ${theme.breakpoints.up('sm')} {
    .article-content-left {
      margin-left: ${theme.spacing(2)};
    }
  
    .article-content-right {
      margin-right: ${theme.spacing(2)};
    }
  }
  `}
`;

const ArticleImage = styled.div`
  flex: 1;
  max-width: 540px;
  min-width: 270px;
`;

const ArticleContent = styled.div`
  max-width: 540px;
  min-width: 270px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${({ theme }) => `
  ${theme.breakpoints.down('xl')} {
    margin-top: ${theme.spacing(4)}
  }
  `}
`;

export default Article;
