import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { Box, Typography } from '@mui/material';
import Layout from '../layout';
import CenteredContainer from '../components/CenteredContainer';
import Article from '../components/Article';
import CallToAction from '../components/CallToAction';

const FinanceProducts = ({ data }) => {
  const { naslov, podnaslov, finansijskiProizvodi } = data.strapiFinansijskiProizvodi;
  const image = getImage(data.strapiFinansijskiProizvodi.cover.localFile);

  return (
    <Layout offset={1}>
      <CenteredContainer>
        <CallToAction image={image} title={naslov} subtitle={podnaslov} />
        <Box maxWidth={640} mx="auto" mt={10} mb={4}>
          <Typography variant="h3" color="primary" align="center" gutterBottom>
            Naši finansijski proizvodi
          </Typography>
          <Box mt={2}>
            <Typography variant="body1" align="center">
              Jednostavan pristup finansijskim sredstvima, brza obrada i ljubazno osoblje, samo je dio razloga zašto
              Mikra svakodnevno postaje bogatija za nove članove naše porodice. Ukoliko ste
              mikropoduzetnica/mikropoduzetnik te vam trebaju finansijska sredstva do 10.000,00 KM za unapređenje vašeg
              malog biznisa, možete da birate jedan od slijedećih finansijskih proizvoda:
            </Typography>
          </Box>
        </Box>
        {finansijskiProizvodi.map((post) => (
          <Article data={post} />
        ))}
      </CenteredContainer>
    </Layout>
  );
};

export const query = graphql`
  query FinansijskiProizvodiQuery {
    strapiFinansijskiProizvodi {
      naslov
      podnaslov
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 460, placeholder: NONE, quality: 90)
          }
        }
      }
      finansijskiProizvodi {
        id
        naslov
        tekst
        align
        prica {
          url
        }
        slika {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 540, height: 448, placeholder: NONE)
            }
          }
        }
      }
    }
  }
`;

export default FinanceProducts;
