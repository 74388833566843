import React from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'gatsby';
import { Typography, Box, Button } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';

const CallToAction = ({ image, bgcolor, bgurl, title, subtitle, to, label, promocode }) => {
  return (
    <ActionContainer bgurl={bgurl} bgcolor={bgcolor}>
      <Box sx={{ px: [4, 10], py: 4, my: [1, 'auto'], width: ['100%', '60%'], maxWidth: 720, zIndex: 3 }}>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2, pr: [2, 8] }}>
          {subtitle}
        </Typography>
        {to && (
          <Button variant="contained" color="secondary" component={RouterLink} to={to} state={{ promocode }}>
            {label}
          </Button>
        )}
      </Box>
      <Box flex="1" position="absolute" right={0} bottom={0} mr={[0, 6, 8]}>
        <GatsbyImage loading="lazy" image={image} alt="" />
      </Box>
    </ActionContainer>
  );
};

const ActionContainer = styled.article`
  display: flex;
  border-radius: 50px;
  position: relative;
  min-height: 380px;
  width: 100%;
  margin: ${({ theme }) => theme.spacing(2)} 0;
  background-color: ${({ theme, bgcolor }) => bgcolor || theme.palette.primary.main};
  background-image: ${({ bgurl }) => `url(${bgurl})`};
  ${({ theme }) => theme.breakpoints.down('xl')} {
    min-height: 520px;
    margin: 0;
  }
  h3,
  h6 {
    color: #fff;
  }
`;

export default CallToAction;
